import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { computed, defineComponent, inject, onMounted, onUnmounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { globalWindowKey } from '@/common/domain/Window';
import { ExclusiveAuctionBidVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-auction/exclusive-auction-bid';

export default defineComponent({
  name: 'ExclusiveAuction',

  components: {
    ExclusiveAuctionBidVue,
  },

  props: {
    exclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
  },

  setup(props) {
    const globalWindow = inject(globalWindowKey)!;
    const { t } = useI18n();

    const intervalId = ref(0);
    const now = ref(Date.now());

    const millisecondsLeft = computed(() => props.exclusive.auction!.endDate.getTime() - now.value);

    const daysLeft = computed(() => Math.floor(millisecondsLeft.value / (1000 * 60 * 60 * 24)));
    const hoursLeft = computed(() => Math.floor((millisecondsLeft.value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    const minutesLeft = computed(() => Math.floor((millisecondsLeft.value % (1000 * 60 * 60)) / (1000 * 60)));
    const secondsLeft = computed(() => Math.floor((millisecondsLeft.value % (1000 * 60)) / 1000));

    const updateNow = () => {
      now.value = Date.now();
    };

    onMounted(() => {
      intervalId.value = globalWindow.setInterval(updateNow, 1000);
    });

    onUnmounted(() => {
      globalWindow.clearInterval(intervalId.value);
    });

    return {
      t,
      millisecondsLeft,
      daysLeft,
      hoursLeft,
      minutesLeft,
      secondsLeft,
    };
  },
});
