import { FanIdentity } from '@/common/domain/auth/FanIdentity';
import { AuthenticationJwtTokenRetriever } from '@/common/domain/auth/AuthenticationJwtTokenRetriever';
import { InjectionKey } from 'vue';
import { Credential } from '@/common/domain/auth/Credential';
import { AuthState } from '@/common/domain/auth/AuthState';

export const authenticationKey: InjectionKey<Authentication> = Symbol();

export interface Authentication extends AuthenticationJwtTokenRetriever {
  login(kycNeeded?: boolean): Promise<void>;
  loginWithCredential(credential: Credential): Promise<AuthState>;
  googleLogin(): Promise<void>;
  signUp(credential: Credential): Promise<void>;
  confirmEmail(confirmationCode: string, email: string): Promise<void>;
  resendConfirmationCode(email: string): Promise<void>;
  logout(redirect: boolean): Promise<void>;
  askResetPassword(email: string): Promise<void>;
  confirmResetPassword(credential: Credential, confirmationCode: string): Promise<void>;
  authenticatedFan(): Promise<FanIdentity>;
  isAuthenticated(): Promise<boolean>;
}
