import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { RandomStringGenerator } from '@/common/domain/RandomStringGenerator';
import { globalWindowKey } from '@/common/domain/Window';
import { MobileWindowChecker, mobileWindowCheckerKey } from '@/common/primary/breakpoint/MobileWindowChecker';
import { MobileGesture, mobileGestureKey } from '@/common/primary/gesture/MobileGesture';
import { PageRedirecter, pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { WindowScroller, windowScrollerKey } from '@/common/primary/WindowScroller';
import { MittAlertBus } from '@/common/secondary/alert/MittAlertBus';
import { createApolloClient } from '@/common/secondary/ApolloClients';
import { BackendCaller } from '@/common/secondary/BackendCaller';
import ConsoleLogger from '@/common/secondary/ConsoleLogger';
import { CurrentBrowserParameters } from '@/common/secondary/CurrentBrowserParameters';
import { KriptownCaller, kriptownCallerKey } from '@/common/secondary/KriptownCaller';
import { MittModalBus } from '@/common/secondary/modal/MittModalBus';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { cardRepositoryKey } from '@/fairplayer/domain/fan/card/CardRepository';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { orderRepositoryKey } from '@/fairplayer/domain/fan/order/OrderRepository';
import { walletRepositoryKey } from '@/fairplayer/domain/fan/wallet/WalletRepository';
import { giveawayParticipationRepositoryKey } from '@/fairplayer/domain/giveaway/GiveawayParticipationRepository';
import { kycRepositoryKey } from '@/fairplayer/domain/kyc/KycRepository';
import { operationRepositoryKey } from '@/fairplayer/domain/operation/OperationRepository';
import {
  ExclusivesFiltersStorage,
  exclusivesFiltersStorageKey,
} from '@/fairplayer/primary/marketplace/exclusives-list/ExclusivesFiltersStorage';
import { BackendClubRepository } from '@/fairplayer/secondary/club/BackendClubRepository';
import { FairplayerClubRepository } from '@/fairplayer/secondary/club/FairplayerClubRepository';
import { UrlParserClubSlugRepository } from '@/fairplayer/secondary/club/UrlParserClubSlugRepository';
import { BackendExclusiveRepository } from '@/fairplayer/secondary/exclusive/backend/BackendExclusiveRepository';
import { FairplayerExclusiveRepository } from '@/fairplayer/secondary/exclusive/FairplayerExclusiveRepository';
import { BackendFanRepository } from '@/fairplayer/secondary/fan/BackendFanRepository';
import { FairplayerCardRepository } from '@/fairplayer/secondary/fan/card/FairplayerCardRepository';
import { KriptownCardRepository } from '@/fairplayer/secondary/fan/card/KriptownCardRepository';
import { MangoPayCardRepository } from '@/fairplayer/secondary/fan/card/MangoPayCardRepository';
import { FairplayerFanRepository } from '@/fairplayer/secondary/fan/FairplayerFanRepository';
import { KriptownViewerRepository } from '@/fairplayer/secondary/fan/KriptownViewerRepository';
import { BackendOrderRepository } from '@/fairplayer/secondary/fan/order/BackendOrderRepository';
import { BackendWalletRepository } from '@/fairplayer/secondary/fan/wallet/BackendWalletRepository';
import { FairplayerWalletRepository } from '@/fairplayer/secondary/fan/wallet/FairplayerWalletRepository';
import { KriptownWalletRepository } from '@/fairplayer/secondary/fan/wallet/KriptownWalletRepository';
import { BackendGiveawayParticipationRepository } from '@/fairplayer/secondary/giveaway/BackendGiveawayParticipationRepository';
import { BackendKycRepository } from '@/fairplayer/secondary/kyc/BackendKycRepository';
import { BackendOperationRepository } from '@/fairplayer/secondary/operation/BackendOperationRepository';
import axios from 'axios';
import MangoPay from 'mangopay-cardregistration-js-kit';
import mitt from 'mitt';
import { InjectionKey } from 'vue';
import { printerBusKey } from '@/common/domain/printer/PrinterBus';
import { MittPrinterBus } from '@/common/secondary/printer/MittPrinterBus';
import { MittHighlightedMediaBus } from '@/common/secondary/highlighted-media/MittHighlightedMediaBus';
import { highlightedMediaBusKey } from '@/common/domain/highlighted-media/HighlightedMediaBus';
import { bottomModalBusKey } from '@/common/domain/bottom-modal/BottomModalBus';
import { MittBottomModalBus } from '@/common/secondary/bottom-modal/MittBottomModalBus';
import { loggerKey } from '@/common/domain/Logger';
import { createFairplayerAuthentication } from '@/providers/FairplayerAuthentication.provider';
import { loginContextRepositoryKey } from '@/common/domain/login/LoginContextRepository';
import { StorageLoginContextRepository } from '@/common/secondary/login/StorageLoginContextRepository';
import { FairplayerFanLanguageRepository } from '@/fairplayer/secondary/fan/FairplayerFanLanguageRepository';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
import { PasswordMeterStrengthRetriever } from '@/common/secondary/login/PasswordMeterStrengthRetriever';
import { passwordStrengthRetrieverKey } from '@/common/domain/login/PasswordStrengthRetriever';
import { BackendProgramRepository } from '@/fairplayer/secondary/program/BackendProgramRepository';
import { programRepositoryKey } from '@/fairplayer/domain/program/ProgramRepository';
import { memberRepositoryKey } from '@/fairplayer/domain/member/MemberRepository';
import { BackendMemberRepository } from '@/fairplayer/secondary/member/BackendMemberRepository';
import { postRepositoryKey } from '@/fairplayer/domain/post/PostRepository';
import { BackendPostRepository } from '@/fairplayer/secondary/post/BackendPostRepository';
import { BackendMessageRepository } from '@/fairplayer/secondary/message/BackendMessageRepository';
import { messageRepositoryKey } from '@/fairplayer/domain/message/MessageRepository';
import { ManifestHandler, manifestHandlerKey } from '@/fairplayer/primary/ManifestHandler';
import { BackendMembershipRepository } from '@/fairplayer/secondary/membership/BackendMembershipRepository';
import { membershipRepositoryKey } from '@/fairplayer/domain/membership/MembershipRepository';
import { BackendDonationRepository } from '@/fairplayer/secondary/donation/BackendDonationRepository';
import { donationRepositoryKey } from '@/fairplayer/domain/donation/DonationRepository';
import { pageScrollBusKey } from '@/common/domain/page-scroll/PageScrollBus';
import { MittPageScrollBus } from '@/common/secondary/page-scroll/MittPageScrollBus';
import { ThemeLocalStorage } from '@/fairplayer/primary/theme/ThemeLocalStorage';
import { themeStorageKey } from '@/fairplayer/domain/theme/ThemeStorage';
import { BackendReferralRepository } from '@/fairplayer/secondary/referral/BackendReferralRepository';
import { referralRepositoryKey } from '@/fairplayer/domain/referral/ReferralRepository';

export const getProvider = <T>(key: InjectionKey<T>): T => providers.get(key);

const provideKey = <T>(key: InjectionKey<T>, value: T): [InjectionKey<T>, T] => [key, value];

const consoleLogger = new ConsoleLogger(console);
const pageRedirecter = new PageRedirecter(window);
const windowScroller = new WindowScroller(window);
const alertBus = new MittAlertBus(mitt());
const pageScrollBus = new MittPageScrollBus(mitt());
const highlightedMediaBus = new MittHighlightedMediaBus(mitt());
const bottomModalBus = new MittBottomModalBus(mitt());
const modalBus = new MittModalBus(mitt());
const printerBus = new MittPrinterBus(mitt());
const themeStorage = new ThemeLocalStorage(window.localStorage, window.document);
const mobileWindowChecker = new MobileWindowChecker(window);
const mobileGesture = new MobileGesture(mitt(), window);
const loginContextRepository = new StorageLoginContextRepository(window.sessionStorage);
const authentication = await createFairplayerAuthentication(pageRedirecter, loginContextRepository, alertBus, consoleLogger);
const clubSlugRepository = new UrlParserClubSlugRepository(window);
const fanLanguageRepository = new FairplayerFanLanguageRepository(window.localStorage, window);
const backendCaller = new BackendCaller(
  axios.create({ baseURL: import.meta.env.VITE_FAIRPLAYER_API_URL }),
  alertBus,
  authentication,
  fanLanguageRepository
);
const kriptownCaller = new KriptownCaller(createApolloClient(authentication), alertBus);

const giveawayParticipationRepository = new BackendGiveawayParticipationRepository(backendCaller);
const backendClubRepository = new BackendClubRepository(backendCaller);
const backendWalletRepository = new BackendWalletRepository(backendCaller, authentication);
const clubRepository = new FairplayerClubRepository(backendClubRepository, clubSlugRepository, window.localStorage);
const manifestHandler = new ManifestHandler(window, clubRepository, consoleLogger);
const backendExclusiveRepository = new BackendExclusiveRepository(backendCaller, authentication);
const kriptownCardRepository = new KriptownCardRepository(kriptownCaller);
const mangoPayCardRepository = new MangoPayCardRepository(MangoPay.cardRegistration, alertBus);
const fairplayerCardRepository = new FairplayerCardRepository(kriptownCardRepository, mangoPayCardRepository);
const kriptownViewerRepository = new KriptownViewerRepository(kriptownCaller);
const backendDonationRepository = new BackendDonationRepository(backendCaller, authentication);
const backendKycRepository = new BackendKycRepository(backendCaller, authentication);
const backendFanRepository = new BackendFanRepository(backendCaller);
const backendReferralRepository = new BackendReferralRepository(backendCaller, authentication);
const backendMemberRepository = new BackendMemberRepository(backendCaller);
const backendMembershipRepository = new BackendMembershipRepository(backendCaller);
const backendMessageRepository = new BackendMessageRepository(backendCaller, authentication);
const operationRepository = new BackendOperationRepository(backendCaller, authentication);
const orderRepository = new BackendOrderRepository(backendCaller, authentication);
const fairplayerFanRepository = new FairplayerFanRepository(
  kriptownViewerRepository,
  backendFanRepository,
  authentication,
  window.localStorage
);
const randomStringGenerator = new RandomStringGenerator();
const passwordMeterStrengthRetriever = new PasswordMeterStrengthRetriever();
const currentBrowserParameters = new CurrentBrowserParameters();
const kriptownWalletRepository = new KriptownWalletRepository(
  kriptownCaller,
  authentication,
  clubRepository,
  randomStringGenerator,
  currentBrowserParameters
);
const exclusivesFiltersStorage = new ExclusivesFiltersStorage(window.sessionStorage);
const fairplayerExclusiveRepository = new FairplayerExclusiveRepository(backendExclusiveRepository);
const fairplayerWalletRepository = new FairplayerWalletRepository(backendWalletRepository, kriptownWalletRepository);
const backendProgramRepository = new BackendProgramRepository(backendCaller);
const backendPostRepository = new BackendPostRepository(backendCaller);

const providers = new Map<InjectionKey<any>, any>([
  provideKey(authenticationKey, authentication),
  provideKey(alertBusKey, alertBus),
  provideKey(kriptownCallerKey, kriptownCaller),
  provideKey(cardRepositoryKey, fairplayerCardRepository),
  provideKey(clubRepositoryKey, clubRepository),
  provideKey(loggerKey, consoleLogger),
  provideKey(exclusivesFiltersStorageKey, exclusivesFiltersStorage),
  provideKey(exclusiveRepositoryKey, fairplayerExclusiveRepository),
  provideKey(globalWindowKey, window),
  provideKey(giveawayParticipationRepositoryKey, giveawayParticipationRepository),
  provideKey(highlightedMediaBusKey, highlightedMediaBus),
  provideKey(donationRepositoryKey, backendDonationRepository),
  provideKey(kycRepositoryKey, backendKycRepository),
  provideKey(fanLanguageRepositoryKey, fanLanguageRepository),
  provideKey(loginContextRepositoryKey, loginContextRepository),
  provideKey(manifestHandlerKey, manifestHandler),
  provideKey(memberRepositoryKey, backendMemberRepository),
  provideKey(membershipRepositoryKey, backendMembershipRepository),
  provideKey(messageRepositoryKey, backendMessageRepository),
  provideKey(mobileGestureKey, mobileGesture),
  provideKey(mobileWindowCheckerKey, mobileWindowChecker),
  provideKey(modalBusKey, modalBus),
  provideKey(bottomModalBusKey, bottomModalBus),
  provideKey(operationRepositoryKey, operationRepository),
  provideKey(orderRepositoryKey, orderRepository),
  provideKey(fanRepositoryKey, fairplayerFanRepository),
  provideKey(pageRedirecterKey, pageRedirecter),
  provideKey(passwordStrengthRetrieverKey, passwordMeterStrengthRetriever),
  provideKey(printerBusKey, printerBus),
  provideKey(programRepositoryKey, backendProgramRepository),
  provideKey(postRepositoryKey, backendPostRepository),
  provideKey(pageScrollBusKey, pageScrollBus),
  provideKey(referralRepositoryKey, backendReferralRepository),
  provideKey(themeStorageKey, themeStorage),
  provideKey(walletRepositoryKey, fairplayerWalletRepository),
  provideKey(windowScrollerKey, windowScroller),
]);

export const provideAll = (provide: (providerKey: InjectionKey<any>, provider: any) => void) => {
  const keys = providers.keys();
  for (let i = 0; i < providers.size; i++) {
    const next = keys.next();
    const key = next.value!;
    const value = providers.get(key);
    provide(key, value);
  }
};
