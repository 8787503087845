import { defineAsyncComponent, markRaw } from 'vue';

export const checkoutRedirectionModal = () =>
  markRaw(
    defineAsyncComponent(
      () => import('@/fairplayer/primary/marketplace/exclusive-page/checkout-redirection-modal/CheckoutRedirectionModal.vue')
    )
  );

export const creditWalletConfirmModal = () =>
  markRaw(
    defineAsyncComponent(
      () => import('@/fairplayer/primary/wallet/cards-list/credit-wallet-form/credit-wallet-confirm-modal/CreditWalletConfirmModal.vue')
    )
  );

export const giveawayParticipationConfirmationModal = () =>
  markRaw(
    defineAsyncComponent(
      () =>
        import(
          '@/fairplayer/primary/giveaway-participation/giveaway-participation-confirmation-modal/GiveawayParticipationConfirmationModal.vue'
        )
    )
  );

export const addBidModal = () =>
  markRaw(
    defineAsyncComponent(() => import('@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/add-bid-modal/AddBidModal.vue'))
  );

export const createReferralModal = () =>
  markRaw(defineAsyncComponent(() => import('@/fairplayer/primary/profile/referral-list/create-referral-modal/CreateReferralModal.vue')));
