import { Referral } from '@/fairplayer/domain/referral/Referral';
import { ReferralId } from '@/fairplayer/domain/referral/ReferralId';

export interface ReferralUi {
  id: ReferralId;
  fanEmail: string;
}

export const fromReferral = (referral: Referral): ReferralUi => ({
  id: referral.id,
  fanEmail: referral.fanEmail,
});
