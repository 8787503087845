import { defineComponent, inject, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { OrderUi } from '@/fairplayer/primary/order-list/Order.ui';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { FairplayerButtonVue } from '@/common/primary/button';
import { printerBusKey } from '@/common/domain/printer/PrinterBus';
import { exclusiveGift } from '@/common/primary/printable/Printable';
import { ExclusiveOrderUi } from '@/fairplayer/primary/order-list/ExclusiveOrder.ui';
import { OrderPriceVue } from './order-price';
import { useRouter } from 'vue-router';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';

export default defineComponent({
  name: 'OrderList',

  components: { FairplayerImageVue, OrderPriceVue, FairplayerButtonVue },

  props: {
    orderList: {
      type: Array as PropType<OrderUi[]>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();

    const clubRepository = inject(clubRepositoryKey)!;
    const printerBus = inject(printerBusKey)!;

    const club = fromClub(clubRepository.getCurrentClub());
    const hasOrders = () => props.orderList.length > 0;

    const goToMarketplace = () => {
      router.push({ name: 'marketplace' });
    };

    const goToOneTimeDonation = () => {
      router.push({ name: 'oneTimeDonation' });
    };

    const print = (exclusive: ExclusiveOrderUi) => {
      const printable = exclusiveGift();
      printerBus.print({ component: printable, options: { exclusive } });
    };

    return {
      goToMarketplace,
      goToOneTimeDonation,
      hasOrders,
      print,
      club,
      t,
    };
  },
});
