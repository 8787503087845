import { Authentication } from '@/common/domain/auth/Authentication';
import { AuthenticationInMemory } from '@/common/secondary/auth/AuthenticationInMemory';
import { PageRedirecter } from '@/common/primary/PageRedirecter';
import { AlertBus } from '@/common/domain/alert/AlertBus';
import ConsoleLogger from '@/common/secondary/ConsoleLogger';
import { LoginContextRepository } from '@/common/domain/login/LoginContextRepository';
import { AuthenticationCognito, configureCognitoWith } from '@/common/secondary/auth/AuthenticationCognito';
import { Amplify } from 'aws-amplify';
import { CognitoClient } from '@/common/secondary/auth/CognitoClient';

export const createFairplayerAuthentication = async (
  pageRedirecter: PageRedirecter,
  loginContextRepository: LoginContextRepository,
  alertBus: AlertBus,
  consoleLogger: ConsoleLogger
): Promise<Authentication> => {
  if (import.meta.env.VITE_ADAPTERS_IN_MEMORY === 'true') {
    return new AuthenticationInMemory(loginContextRepository, pageRedirecter);
  }

  configureCognitoWith(Amplify.configure, alertBus);
  return new AuthenticationCognito(new CognitoClient(), alertBus, consoleLogger);
};
