.fp-card(:class='{"-highlighted": highlighted}' data-test='exclusive-auction-bid')
  .fp-horizontal-spacing.-items-center.-s-sm
    .fp-horizontal-spacing--column
      .fp-image.-circled
        FairplayerImageVue(:url='bid.bidder.pictureUrl' :fallback-image='FairplayerFallbackImageUi.FAN')
    .fp-horizontal-spacing--column.-expand
      .fp-vertical-spacing.-s-xs
        .fp-vertical-spacing--line
          span.fp-text.-text-sm.-text-semi-bold {{ bid.bidder.firstName }} {{ bid.bidder.lastName }}
        .fp-vertical-spacing--line
          RelativeTimeVue(:date='bid.date' sm)
    .fp-horizontal-spacing--column
      span.fp-text.-text-semi-bold {{ bid.price.totalCost.text }}
