import { Referral } from '@/fairplayer/domain/referral/Referral';

export interface BackendReferral {
  id: string;
  creationDate: string;
  fanEmail: string;
}

export const toReferral = (backendReferral: BackendReferral): Referral => ({
  id: backendReferral.id,
  creationDate: new Date(backendReferral.creationDate),
  fanEmail: backendReferral.fanEmail,
});
