import { Observable } from '@/common/domain/Observable';
import { Optional } from '@/common/domain/Optional';
import { Fiat } from '@/common/domain/token/Fiat';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { FanPersonalInfo } from '@/fairplayer/domain/fan/FanPersonalInfo';
import { InjectionKey } from 'vue';
import { Club } from '@/fairplayer/domain/club/Club';
import { FanToCreate } from '@/fairplayer/domain/fan/FanToCreate';

export const fanRepositoryKey: InjectionKey<FanRepository> = Symbol();

export interface FanRepository {
  registerFan(fanToCreate: FanToCreate): Promise<void>;
  getForClub(club: Club, forceRefresh?: boolean): Promise<Fan>;
  isKycAnnouncementIgnored(): boolean;
  ignoreKycAnnouncement(): void;
  sendPersonalInfoFor(fanInfo: FanPersonalInfo, clubSlug: ClubSlug): Promise<Fan>;
  uploadKycDocuments(files: File[]): Promise<boolean>;
  acceptTos(clubSlug: ClubSlug): Promise<void>;
  observableFiatNotProcessed(): Observable<Optional<Fiat>>;
}
