import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';
import { memberRepositoryKey } from '@/fairplayer/domain/member/MemberRepository';
import { fromMember, MemberUi } from '@/fairplayer/primary/member/Member.ui';
import { MemberCardVue } from '@/fairplayer/primary/member/members-list/member-card';
import { messageRepositoryKey } from '@/fairplayer/domain/message/MessageRepository';
import { fromInterlocutorMessages, InterlocutorMessagesUi } from '@/fairplayer/primary/InterlocutorMessages.ui';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { InterlocutorsListVue } from '@/fairplayer/primary/member/members-list/interlocutors-list';
import { InterlocutorMessages } from '@/fairplayer/domain/message/InterlocutorMessages';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';

export default defineComponent({
  name: 'MembersList',

  components: {
    PageVue,
    MemberCardVue,
    InterlocutorsListVue,
  },

  setup() {
    const clubRepository = inject(clubRepositoryKey)!;
    const fanLanguageRepository = inject(fanLanguageRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const memberRepository = inject(memberRepositoryKey)!;
    const messageRepository = inject(messageRepositoryKey)!;
    const { t } = useI18n();

    const interlocutorsMessages = ref(Loader.loading<InterlocutorMessagesUi[]>());
    const membersList = ref(Loader.loading<MemberUi[]>());
    const fan = ref<Fan>();

    const fanLanguage = fanLanguageRepository.getCurrentLanguage();

    const hasConversations = computed(() => !interlocutorsMessages.value.isLoading() && interlocutorsMessages.value.value().length > 0);

    onMounted(async () => {
      await retrieveFan();
      await loadMemberList();
      await loadMessages();
    });

    const loadMemberList = async (): Promise<void> => {
      try {
        const memberListResponse = await memberRepository.getForClub(clubRepository.getCurrentSlug());
        membersList.value.loaded(memberListResponse.map(member => fromMember(member, fan.value?.id === member.id)));
      } catch (error: any) {
        logger.error('Failed to retrieve members', error);
        membersList.value.loaded([]);
      }
    };

    const retrieveFan = async () => {
      await fanRepository
        .getForClub(clubRepository.getCurrentClub())
        .then((retrievedFan: Fan) => (fan.value = retrievedFan))
        .catch((error: any) => {
          logger.error('Failed to retrieve fan', error);
        });
    };

    const loadMessages = async (): Promise<void> => {
      try {
        const messagesResponse = await messageRepository.getForClub(clubRepository.getCurrentSlug());
        interlocutorsMessages.value.loaded(
          messagesResponse.reduce((acc: InterlocutorMessagesUi[], interlocutorMessages: InterlocutorMessages) => {
            const foundInterlocutor = membersList.value.value().find(member => member.id === interlocutorMessages.interlocutorId);

            if (foundInterlocutor) {
              acc.push(fromInterlocutorMessages(interlocutorMessages.interlocutorId, foundInterlocutor, fanLanguage, interlocutorMessages));
            }

            return acc;
          }, [])
        );
      } catch (error: any) {
        logger.error('Failed to retrieve messages', error);
        interlocutorsMessages.value.loaded([]);
      }
    };

    return {
      interlocutorsMessages,
      hasConversations,
      membersList,
      t,
    };
  },
});
