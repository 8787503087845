import { InjectionKey } from 'vue';
import { Referral } from '@/fairplayer/domain/referral/Referral';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { ReferralToCreate } from '@/fairplayer/domain/referral/ReferralToCreate';

export const referralRepositoryKey: InjectionKey<ReferralRepository> = Symbol();

export interface ReferralRepository {
  list(clubSlug: ClubSlug): Promise<Referral[]>;
  create(clubSlug: ClubSlug, referral: ReferralToCreate): Promise<void>;
}
