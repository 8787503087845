include ../../../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing--line
  .fp-horizontal-spacing.-equivalent.-s-sm
    .fp-horizontal-spacing--column
      .fp-card
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-sm {{ t('marketplace.exclusiveDetail.auction.startingPrice') }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-semi-bold {{ exclusive.auction.startingPrice.totalCost.text }}
    .fp-horizontal-spacing--column
      .fp-card.-highlighted
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-sm {{ t('marketplace.exclusiveDetail.auction.lastBid') }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-semi-bold {{ exclusive.auction.currentPrice.totalCost.text }}
    .fp-horizontal-spacing--column
      .fp-card
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-sm {{ t('marketplace.exclusiveDetail.auction.bids') }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-semi-bold {{ exclusive.auction.bids.length }}
.fp-vertical-spacing--line(v-if='millisecondsLeft > 0' data-test='time-left')
  .fp-card
    .fp-horizontal-spacing.-equivalent
      .fp-horizontal-spacing--column
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-lg.-text-semi-bold {{ daysLeft }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-xs.-text-uppercase {{ t('marketplace.exclusiveDetail.auction.countdown.days') }}
      .fp-horizontal-spacing--column
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-lg.-text-semi-bold {{ hoursLeft }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-xs.-text-uppercase {{ t('marketplace.exclusiveDetail.auction.countdown.hours') }}
      .fp-horizontal-spacing--column
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-lg.-text-semi-bold {{ minutesLeft }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-xs.-text-uppercase {{ t('marketplace.exclusiveDetail.auction.countdown.minutes') }}
      .fp-horizontal-spacing--column
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-lg.-text-semi-bold {{ secondsLeft }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-xs.-text-uppercase {{ t('marketplace.exclusiveDetail.auction.countdown.seconds') }}
.fp-vertical-spacing--line
  .fp-vertical-spacing.-s-sm
    .fp-vertical-spacing--line
      h2.fp-title {{ t('marketplace.exclusiveDetail.auction.bidders') }}
    .fp-vertical-spacing--line(v-for='(bid, index) in exclusive.auction.bids' :key='bid.id' :data-test='`bid-${index}`')
      ExclusiveAuctionBidVue(:bid='bid' :highlighted='index === 0')
