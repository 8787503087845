include ../../../../common/primary/icon/icon.mixin.pug

.fp-collapsible-card(v-if='!referralList.isLoading()' data-test='referrals')
  input#referral-list(type='checkbox', name='collapse')
  label.fp-collapsible-card--trigger(for='referral-list' data-test='referrals.trigger')
    +fp-icon('mail', {size: '16'})
    span.fp-text.-text-semi-bold {{ t('referrals.title') }}
    +fp-icon('chevron-right', {size: '12'})
  .fp-collapsible-card--content
    .fp-vertical-spacing.-s-lg
      .fp-vertical-spacing--line(v-if='!hasReferrals')
        .fp-text(data-test='no-referrals') {{ t('referrals.noReferrals') }}
      .fp-vertical-spacing--line(v-for='(referral, index) in referralList.value()' :key='`referral-${index}`')
        .fp-text.-text-semi-bold {{ referral.fanEmail }}
      .fp-vertical-spacing--line
        a.fp-link(@click='openCreateReferralModal' data-test='create')
          .fp-icon-with-text
            +fp-icon('plus', {size: '16'})
            .fp-icon-with-text--text {{ t('referrals.create') }}
