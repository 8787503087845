.fp-vertical-spacing--line(v-for='(post, index) in limitedPosts' :key='index')
  .fp-illustrated-item(:data-test='`post-${index}`')
    .fp-image.-lg.-rounded
      FairplayerImageVue(:url='post.imageUrl' highlightable data-test='image')
    .fp-illustrated-item--content
      .fp-vertical-spacing.-s-xs
        .fp-vertical-spacing--line
          .fp-text.-color-shade-500.-text-sm(data-test='date') {{ post.date }}
        .fp-vertical-spacing--line
          .fp-text.-text-semi-bold(data-test='name') {{ post.name }}
        .fp-vertical-spacing--line
          router-link.fp-link.-secondary(:to='{name: \'postPage\', params: { postSlug: post.slug }}' data-test='post-page') {{ t('posts.seeMore') }}
