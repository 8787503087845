import { Optional } from '@/common/domain/Optional';
import { Club } from '@/fairplayer/domain/club/Club';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { ClubSummary } from '@/fairplayer/domain/club/ClubSummary';
import { PaymentTypeAvailability } from '@/fairplayer/domain/club/PaymentTypeAvailability';
import { PaymentType } from '@/fairplayer/domain/fan/order/PaymentType';
import { InjectionKey } from 'vue';

export const clubRepositoryKey: InjectionKey<ClubRepository> = Symbol();

export interface ClubRepository {
  list(): Promise<ClubSummary[]>;
  get(clubSlug: ClubSlug): Promise<Club>;
  retrieveClub(): Promise<Club>;
  findSelectedSlug(): Optional<ClubSlug>;
  isCustomClubDomain(): boolean;
  clearSelectedClub(): void;
  getCurrentSlug(): ClubSlug;
  findCurrentClub(): Optional<Club>;
  getCurrentClub(): Club;
  setCurrentClub(club: Club): void;
  getPaymentTypeAvailability(slug: ClubSlug, paymentType: PaymentType): Promise<PaymentTypeAvailability>;
}
