include ../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing.-s-lg(v-if='!fan.isLoading()' data-test='profile')
      .fp-vertical-spacing--line.-justify-center(data-test='picture')
        .fp-image.-lg.-circled
          FairplayerImageVue(:url='fan.value().identity.pictureUrl.orElse("")' :fallback-image='FairplayerFallbackImageUi.FAN')
      .fp-vertical-spacing--line.-justify-center
        .fp-horizontal-spacing.-items-center.-s-sm
          .fp-horizontal-spacing--column
            h1.fp-title(data-test='name') {{ fan.value().identity.name }}
          .fp-horizontal-spacing--column(v-if='kycValidated')
            span(data-test='validated-icon' :title='t("profile.verifiedUser")')
              +fp-icon('circle-wavy-check', {size: '24', color: 'success'})
      .fp-separator.-secondary
      .fp-vertical-spacing--line(v-if='!orderList.isLoading()')
        OrderListVue(:order-list='orderList.value()')
      .fp-vertical-spacing--line(v-if='!donationList.isLoading()')
        DonationListVue(:donation-list='donationList.value()')
      .fp-vertical-spacing--line(v-if='isFoundation')
        ReferralListVue
      .fp-vertical-spacing--line(v-if='isMarketplace')
        router-link.fp-link.-no-text-decoration(:to='{name: \'clubsPage\'}')
          .fp-card(data-test='go-to-club-selection')
            .fp-text.-text-semi-bold {{ t('profile.goToClubSelection') }}
  template(v-slot:footer)
    LogoutVue
