const ANNOUNCEMENT_DEV_KEY = 'announcement.dev';
const ANNOUNCEMENT_KYC_KEY = 'announcement.kyc';

export interface AnnouncementUi {
  messageKey: string;
  showCloseAction: boolean;
  isWarning: boolean;
  messageArguments?: Record<string, any>;
  routeLink?: string;
  linkKey?: string;
}

export const incompleteKycAnnouncementUi = (routeLink: string): AnnouncementUi => ({
  messageKey: `${ANNOUNCEMENT_KYC_KEY}.incomplete`,
  showCloseAction: true,
  isWarning: true,
  routeLink,
  linkKey: `${ANNOUNCEMENT_KYC_KEY}.link`,
});

export const pendingKycAnnouncementUi = (): AnnouncementUi => ({
  messageKey: `${ANNOUNCEMENT_KYC_KEY}.pending`,
  showCloseAction: true,
  isWarning: true,
});

export const createDevEnvironmentAnnouncementUi = (): AnnouncementUi => ({
  messageKey: `${ANNOUNCEMENT_DEV_KEY}.message`,
  showCloseAction: true,
  isWarning: false,
  routeLink: 'https://app.fairplayer.co',
  linkKey: `${ANNOUNCEMENT_DEV_KEY}.link`,
});

export const createArchivedClubAnnouncementUi = (): AnnouncementUi => ({
  messageKey: 'announcement.club.archived',
  showCloseAction: false,
  isWarning: true,
});
