import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { InterlocutorMessagesUi } from '@/fairplayer/primary/InterlocutorMessages.ui';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';

export default defineComponent({
  name: 'InterlocutorsListItem',

  components: {
    FairplayerImageVue,
  },

  props: {
    conversation: {
      type: Object as PropType<InterlocutorMessagesUi>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const lastMessage = computed(() => {
      const lastDayMessage = props.conversation.datedMessages[props.conversation.datedMessages.length - 1];
      return lastDayMessage.messages[lastDayMessage.messages.length - 1];
    });

    return {
      t,
      lastMessage,
      FairplayerFallbackImageUi,
    };
  },
});
