import { BackendCaller } from '@/common/secondary/BackendCaller';
import { DonationRepository } from '@/fairplayer/domain/donation/DonationRepository';
import { CheckoutSession } from '@/fairplayer/domain/CheckoutSession';
import { BackendCheckoutSession, toCheckoutSession } from '@/fairplayer/secondary/BackendCheckoutSession';
import { DonationToCreate } from '@/fairplayer/domain/donation/DonationToCreate';
import { fromDonationToCreate } from '@/fairplayer/secondary/donation/BackendDonationToCreate';
import { Donation } from '@/fairplayer/domain/donation/Donation';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Authentication } from '@/common/domain/auth/Authentication';
import { BackendDonation, toDonation } from '@/fairplayer/secondary/donation/BackendDonation';

export class BackendDonationRepository implements DonationRepository {
  constructor(
    private backendCaller: BackendCaller,
    private authentication: Authentication
  ) {}

  async create(donationToCreate: DonationToCreate): Promise<CheckoutSession> {
    return this.backendCaller
      .post<BackendCheckoutSession>(`api/clubs/${donationToCreate.clubSlug}/donations`, fromDonationToCreate(donationToCreate))
      .then(checkoutSession => toCheckoutSession(checkoutSession.data));
  }

  async listFor(clubSlug: ClubSlug): Promise<Donation[]> {
    const authenticatedFan = await this.authentication.authenticatedFan();
    return await this.backendCaller
      .get<BackendDonation[]>(`/api/clubs/${clubSlug}/fans/${encodeURIComponent(authenticatedFan.username)}/donations`)
      .then(donations => donations.data.map(toDonation));
  }
}
