import { Authentication } from '@/common/domain/auth/Authentication';
import { FanIdentity } from '@/common/domain/auth/FanIdentity';
import { Observable } from '@/common/domain/Observable';
import { Optional } from '@/common/domain/Optional';
import { Fiat } from '@/common/domain/token/Fiat';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { FanPersonalInfo } from '@/fairplayer/domain/fan/FanPersonalInfo';
import { FanRepository } from '@/fairplayer/domain/fan/FanRepository';
import { IdDocumentError, IdDocumentErrorType } from '@/fairplayer/domain/fan/IdDocumentError';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';
import { BackendFan } from '@/fairplayer/secondary/fan/BackendFan';
import { BackendIdentityProof } from '@/fairplayer/secondary/fan/BackendFanKycInfo';
import { noPersonalInfo, toFanPersonalInfo } from '@/fairplayer/secondary/fan/BackendFanPersonalInfo';
import { BackendFanRepository } from '@/fairplayer/secondary/fan/BackendFanRepository';
import { KriptownViewerRepository } from '@/fairplayer/secondary/fan/KriptownViewerRepository';
import { computeDocumentError, computeDocumentStatus, isExpired } from '@/fairplayer/secondary/fan/KycDocumentDetails';
import { Club } from '@/fairplayer/domain/club/Club';
import { FanToCreate } from '@/fairplayer/domain/fan/FanToCreate';

const HIDE_KYC_ANNOUNCEMENT = 'hide_kyc_announcement';

const computeKycStatus = (fan: BackendFan) => {
  if (fan.kycInfo.kycRegular) {
    return KycStatus.KYC_VALIDATED;
  }

  if (fan.kycInfo.tosAcceptedAt === null) {
    return KycStatus.KYC_NOT_STARTED;
  }

  if (noPersonalInfo(fan.personalInfo)) {
    return KycStatus.TOS_VALIDATED;
  }

  if (fan.kycInfo.lcbftStatus === 'LOCKED_BY_ANSWERS') {
    return KycStatus.FAN_PERSONAL_INFO_FILLED;
  }

  const identityProofStatus = computeDocumentStatus(fan.kycInfo.identityProof);
  switch (identityProofStatus) {
    case 'TO_SEND':
      return KycStatus.KYC_QUESTIONS_ANSWERED;
    case 'IN_REVIEW':
      return KycStatus.ID_DOCUMENT_UPLOADED;
    case 'REFUSED':
    case 'EXPIRED':
      return KycStatus.ID_DOCUMENT_REFUSED;
    default:
      return KycStatus.KYC_REFUSED;
  }
};

const documentError = (type: IdDocumentErrorType, message?: string): Optional<IdDocumentError> =>
  Optional.of({ type, message: Optional.ofUndefinable(message) });

const toIdDocumentError = (kycDocumentDetails: BackendIdentityProof | null, kycStatus: KycStatus): Optional<IdDocumentError> => {
  if (isExpired(kycDocumentDetails)) {
    return documentError(IdDocumentErrorType.EXPIRED);
  }

  if (kycStatus !== KycStatus.ID_DOCUMENT_REFUSED) {
    return Optional.empty();
  }
  const kycDocumentError = computeDocumentError(kycDocumentDetails);

  if (kycDocumentError.type === 'DOCUMENT_UNREADABLE') {
    return documentError(IdDocumentErrorType.UNREADABLE);
  }

  if (kycDocumentError.type === 'DOCUMENT_HAS_EXPIRED') {
    return documentError(IdDocumentErrorType.EXPIRED);
  }

  if (kycDocumentError.message) {
    return documentError(IdDocumentErrorType.MESSAGE, kycDocumentError.message);
  }

  return documentError(IdDocumentErrorType.UNKNOWN);
};

const extractIdentity = (
  personalInfo: Optional<FanPersonalInfo>,
  backendPictureUrl: string | null,
  authenticatedFan: FanIdentity
): FanIdentity => {
  const pictureUrl = Optional.ofUndefinable(backendPictureUrl).or(authenticatedFan.pictureUrl);

  return personalInfo
    .map(info => ({
      firstName: info.firstName,
      lastName: info.lastName,
      name: `${info.firstName} ${info.lastName}`,
      username: authenticatedFan.username,
      email: authenticatedFan.email,
      pictureUrl,
    }))
    .orElse({ ...authenticatedFan, pictureUrl });
};

const toFan = (fan: BackendFan, authenticatedFan: FanIdentity) => {
  const { membershipStatus, pictureUrl } = fan;
  const personalInfo = toFanPersonalInfo(fan.personalInfo);
  const kycStatus = computeKycStatus(fan);
  const idDocumentError = toIdDocumentError(fan.kycInfo.identityProof, kycStatus);
  const identity = extractIdentity(personalInfo, pictureUrl, authenticatedFan);

  return {
    id: fan.identifier.id,
    kycStatus,
    identity,
    membershipStatus,
    personalInfo,
    idDocumentError,
  };
};

export class FairplayerFanRepository implements FanRepository {
  constructor(
    private kriptownViewerRepository: KriptownViewerRepository,
    private backendFanRepository: BackendFanRepository,
    private authentication: Authentication,
    private storage: Storage
  ) {}

  async registerFan(fanToCreate: FanToCreate): Promise<void> {
    return this.backendFanRepository.registerFan(fanToCreate);
  }

  async getForClub(club: Club, forceRefresh = false): Promise<Fan> {
    return Promise.all([this.getFan(club, forceRefresh), this.authentication.authenticatedFan()]).then(([backendFan, authenticatedFan]) =>
      toFan(backendFan, authenticatedFan)
    );
  }

  private async getFan(club: Club, forceRefresh: boolean): Promise<BackendFan> {
    return await this.backendFanRepository.getFan(club.slug, forceRefresh);
  }

  isKycAnnouncementIgnored(): boolean {
    return this.storage.getItem(HIDE_KYC_ANNOUNCEMENT) !== null;
  }

  ignoreKycAnnouncement(): void {
    this.storage.setItem(HIDE_KYC_ANNOUNCEMENT, 'true');
  }

  async sendPersonalInfoFor(fanInfo: FanPersonalInfo, clubSlug: ClubSlug): Promise<Fan> {
    const authenticatedFan = await this.authentication.authenticatedFan();
    const backendFan = await this.backendFanRepository.sendPersonalFanInfoFor(fanInfo, clubSlug, authenticatedFan.username);

    return toFan(backendFan, authenticatedFan);
  }

  async uploadKycDocuments(files: File[]): Promise<boolean> {
    return this.kriptownViewerRepository.uploadKycDocument(files);
  }

  acceptTos(clubSlug: ClubSlug): Promise<void> {
    return this.backendFanRepository.acceptTos(clubSlug);
  }

  observableFiatNotProcessed(): Observable<Optional<Fiat>> {
    return this.kriptownViewerRepository.observableViewerBalance();
  }
}
