include ../../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    NotFoundVue(v-if='displayPostNotFound' :not-found-ui='postNotFoundUi()')
    .fp-post(v-if='!postUi.isLoading() && postUi.value()' data-test='post')
      .fp-post--content
        .fp-display.-wide(data-test='image')
          .fp-display--image
            FairplayerImageVue(:url='postUi.value().imageUrl' :alt='postUi.value().name' @click='highlightPostMedias(0)')
            .fp-display--left-action(@click='goToNewsfeed' data-test='go-to-newsfeed')
              +fp-icon('arrow-left', {size: '16'})
        .fp-text.-color-shade-500 {{ postUi.value().date }}
        h1.fp-title(data-test='name') {{ postUi.value().name }}
        span.fp-text(v-html='postUi.value().description' data-test='description')
        router-link.fp-link.-no-text-decoration(v-if='!program.isLoading() && program.value()' data-test='program' :to='{name: \'programPage\', params: { programSlug: program.value().slug }}')
          .fp-featured-content
            img(:src='program.value().imageUrl' :alt='program.value().name')
            .fp-featured-content--background
            .fp-featured-content--description
              span.fp-text.-text-2xl.-text-semi-bold(data-test='program-name') {{ program.value().name }}
              .fp-text.-lines-1(v-html='program.value().description')
      aside(v-if='postUi.value().medias.length')
        .fp-post--medias(data-test='medias')
          template(v-for='(media, index) in postUi.value().medias' :key='index')
            .fp-image.-rounded.-expand.-wide(v-if='media.type === \'IMAGE\'')
              FairplayerImageVue(:url='media.url' :alt='postUi.value().name' @click='highlightPostMedias(index + 1)')
            FairplayerVideoVue(v-else :url='media.url' @click='highlightPostMedias(index + 1)' wide)
