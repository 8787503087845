import { MessageUi } from '@/fairplayer/primary/Message.ui';

export interface DatedMessagesUi {
  date: string;
  messages: MessageUi[];
}

export const fromMessagesUi = (messages: MessageUi[], locale: string): DatedMessagesUi[] => {
  const groupedByDate = messages.reduce((acc: Record<string, MessageUi[]>, message) => {
    const messageDate = message.date.toLocaleDateString(locale, { day: 'numeric', month: 'long' });

    if (!acc[messageDate]) {
      acc[messageDate] = [];
    }
    acc[messageDate].push(message);

    return acc;
  }, {});

  return Object.keys(groupedByDate).map(date => ({
    date,
    messages: groupedByDate[date],
  }));
};
