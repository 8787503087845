import { Donation } from '@/fairplayer/domain/donation/Donation';
import { BackendFiat, toFiat } from '@/common/secondary/token/BackendFiat';

export interface BackendDonation {
  id: string;
  amount: BackendFiat;
  date: string;
}

export const toDonation = (donation: BackendDonation): Donation => ({
  id: donation.id,
  amount: toFiat(donation.amount),
  date: new Date(donation.date),
});
