import { InjectionKey } from 'vue';
import { CheckoutSession } from '@/fairplayer/domain/CheckoutSession';
import { DonationToCreate } from '@/fairplayer/domain/donation/DonationToCreate';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Donation } from '@/fairplayer/domain/donation/Donation';

export const donationRepositoryKey: InjectionKey<DonationRepository> = Symbol();

export interface DonationRepository {
  create(donationToCreate: DonationToCreate): Promise<CheckoutSession>;
  listFor(clubSlug: ClubSlug): Promise<Donation[]>;
}
