import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { KycStepperVue } from '@/fairplayer/primary/kyc-stepper';
import { PageVue } from '@/fairplayer/primary/page';
import { inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ClubModel } from '@/fairplayer/domain/club/ClubModel';
import { MembershipStatus } from '@/fairplayer/domain/fan/MembershipStatus';

export default {
  name: 'KycForm',

  components: {
    KycStepperVue,
    PageVue,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const clubRepository = inject(clubRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const fan = ref<Fan>();

    const club = clubRepository.getCurrentClub();

    onMounted(async () => {
      if (club.model === ClubModel.MARKETPLACE) {
        await goClubHomepage();
      } else {
        await retrieveFan();
      }
    });

    const retrieveFan = async () => {
      fan.value = await fanRepository.getForClub(club);
    };

    const goClubHomepage = async () => router.push({ name: 'clubHomepage' });
    const goToProfile = () => router.push({ name: 'profile' });

    const resetFanStatusTo = (membershipStatus: MembershipStatus) => {
      fan.value = {
        ...fan.value!,
        membershipStatus,
      };
    };

    return {
      resetFanStatusTo,
      fan,
      goToProfile,
      t,
    };
  },
};
