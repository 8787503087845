import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { fromReferral, ReferralUi } from '@/fairplayer/primary/profile/referral-list/Referral.ui';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { createReferralModal } from '@/common/primary/modal/Modals';
import { referralRepositoryKey } from '@/fairplayer/domain/referral/ReferralRepository';
import { Loader } from '@/loader/primary/Loader';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { ReferralToCreate } from '@/fairplayer/domain/referral/ReferralToCreate';
import { loggerKey } from '@/common/domain/Logger';

export default defineComponent({
  name: 'ReferralList',

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const modalBus = inject(modalBusKey)!;
    const logger = inject(loggerKey)!;
    const referralRepository = inject(referralRepositoryKey)!;

    const referralList = ref(Loader.loading<ReferralUi[]>());

    const hasReferrals = computed(() => !referralList.value.isLoading() && referralList.value.value().length > 0);

    const retrieveReferrals = async () => {
      referralList.value.loaded(
        await referralRepository.list(clubRepository.getCurrentSlug()).then(referrals => referrals.map(fromReferral))
      );
    };

    const openCreateReferralModal = () => {
      const modal = createReferralModal();
      modalBus.open({
        component: modal,
        options: {
          created: createReferral,
        },
      });
    };

    const createReferral = async (referralToCreate: ReferralToCreate) => {
      await referralRepository
        .create(clubRepository.getCurrentSlug(), referralToCreate)
        .then(() => retrieveReferrals())
        .catch((error: any) => {
          logger.error('Failed to create referral', error);
        })
        .finally(() => modalBus.close());
    };

    onMounted(async () => {
      await retrieveReferrals();
    });

    return {
      hasReferrals,
      referralList,
      createReferral,
      openCreateReferralModal,
      t,
    };
  },
});
