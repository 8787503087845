include ../../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:content v-if='conversation.isLoading()')
    form.fp-chat
      .fp-chat--header
        .fp-placeholder
      .fp-chat--messages
      .fp-chat--input
        .fp-icons-input
          input.fp-input-text(:placeholder='t(\'chat.writeMessage\')')
          .fp-icons-input--right-icon
            button.fp-icon.fp-glyph-send.-s16.-pointer
  template(v-slot:content v-if='!conversation.isLoading()')
    form.fp-chat(@submit.prevent='sendMessage' data-test='chat')
      .fp-chat--header
        router-link.fp-link.-no-text-decoration(:to='{name: \'members\'}')
          +fp-icon('arrow-left', {pointer: true})
        .fp-image.-circled
          FairplayerImageVue(:url='conversation.value().interlocutorPictureUrl' :fallback-image='FairplayerFallbackImageUi.FAN')
        span.fp-text.-color-shade-900.-text-semi-bold(data-test='interlocutor-name') {{ conversation.value().interlocutorFirstName }} {{ conversation.value().interlocutorLastName }}
      .fp-chat--messages
        template(v-for='(datedMessage, index) in conversation.value().datedMessages')
          .fp-chat--messages-date(data-test='date') {{ datedMessage.date }}
          template(v-for='(message, j) in datedMessage.messages')
            .fp-horizontal-spacing.-items-end.-s-sm(v-if='message.received' :data-test='`message-${j}`')
              .fp-horizontal-spacing--column
                .fp-image.-circled
                  FairplayerImageVue(:url='conversation.value().interlocutorPictureUrl' :fallback-image='FairplayerFallbackImageUi.FAN')
              .fp-horizontal-spacing--column
                .fp-card
                  span.fp-text.-text-sm {{ message.text }}
            .fp-chat--message-sent(v-else :data-test='`message-${j}`')
              .fp-card.-dark
                span.fp-text.-text-sm {{ message.text }}
      .fp-chat--input
        .fp-icons-input
          input.fp-input-text(:placeholder='t(\'chat.writeMessage\')' v-model='message' data-test='chat-input')
          .fp-icons-input--right-icon
            button.fp-icon.fp-glyph-send.-s16.-pointer(type='submit')
