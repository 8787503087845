import { BackendCaller } from '@/common/secondary/BackendCaller';
import { Authentication } from '@/common/domain/auth/Authentication';
import { ReferralRepository } from '@/fairplayer/domain/referral/ReferralRepository';
import { Referral } from '@/fairplayer/domain/referral/Referral';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { BackendReferral, toReferral } from '@/fairplayer/secondary/referral/BackendReferral';
import { fromReferralToCreate } from '@/fairplayer/secondary/referral/BackendReferralToCreate';
import { ReferralToCreate } from '@/fairplayer/domain/referral/ReferralToCreate';

export class BackendReferralRepository implements ReferralRepository {
  constructor(
    private backendCaller: BackendCaller,
    private authentication: Authentication
  ) {}

  async list(clubSlug: ClubSlug): Promise<Referral[]> {
    const authenticatedFan = await this.authentication.authenticatedFan();

    return this.backendCaller
      .get<BackendReferral[]>(`api/clubs/${clubSlug}/fans/${encodeURIComponent(authenticatedFan.username)}/referrals`)
      .then(({ data }) => data.map(toReferral));
  }

  async create(clubSlug: ClubSlug, referralToCreate: ReferralToCreate): Promise<void> {
    const authenticatedFan = await this.authentication.authenticatedFan();

    await this.backendCaller.post<void>(
      `api/clubs/${clubSlug}/fans/${encodeURIComponent(authenticatedFan.username)}/referrals`,
      fromReferralToCreate(referralToCreate)
    );
  }
}
