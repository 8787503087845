include ../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing(v-if='exclusives.isLoading()')
      .fp-vertical-spacing--line
        .fp-placeholder.-height-sm
      .fp-vertical-spacing--line
        .fp-placeholder.-squared
    .fp-vertical-spacing(v-if='!exclusives.isLoading()')
      template(v-if='exclusives.value().length')
        .fp-vertical-spacing--line
          h1.fp-title {{ t('oneTimeDonation.contribute') }}
        .fp-separator.-secondary
          .fp-separator--text {{ t('oneTimeDonation.featured') }}
        .fp-vertical-spacing--line
          .fp-responsive-grid.-cols3.-cols1-up-sm.-cols2-up-md
            .fp-responsive-grid--column(v-for='(exclusive, index) in exclusives.value()' :key='index' data-test='exclusives')
              router-link.fp-link.-no-text-decoration(:to='exclusive.route' :id='exclusive.id')
                PresentationCardVue(:item='exclusive' :data-test='`exclusive-${index}`')
      .fp-vertical-spacing--line
        .fp-separator.-secondary
          .fp-separator--text {{ t('oneTimeDonation.freeAmount') }}
      .fp-vertical-spacing--line
        .fp-card
          form.fp-vertical-spacing.-s-lg(@submit.prevent='createDonation' data-test='create-donation-form')
            .fp-vertical-spacing--line
              .fp-text {{ t('oneTimeDonation.thanks') }}
            .fp-vertical-spacing--line
              label.fp-field
                | {{ t('oneTimeDonation.amountDescription') }}
                .fp-icons-input
                  input.fp-input-text(min='1000' :placeholder=`t('oneTimeDonation.amount')` v-model='amount' data-test='amount' required)
                  .fp-icons-input--right-icon
                    i.fp-icon.fp-glyph-euro.-s16
            .fp-vertical-spacing--line(v-if='paymentFailed' data-test='payment-failed')
              .fp-alert.-danger
                +fp-icon('close-circle', {color: 'danger', size: '18'})
                span.fp-text.-text-sm {{ t('oneTimeDonation.paymentFailed') }}
            .fp-vertical-spacing--line
              FairplayerButtonVue(data-test='create-donation' icon='hand-heart' expand type='submit' :loading='isCreatingOneTimeDonation' :text='t("oneTimeDonation.makeDonation")')
